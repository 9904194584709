import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Contact Me`}</h1>
    <h3>{`Email: `}<a parentName="h3" {...{
        "href": "mailto:admin@beeslens.com"
      }}>{`admin@beeslens.com`}</a></h3>
    <h3>{`Phone: `}<a parentName="h3" {...{
        "href": "tel:+44-787-166-0038"
      }}>{`07871660038`}</a></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      